export function stringToCmsVariableName(input = ''): string {
  let result = input
    .replace(/ /g, '_')
    .replace(/\(/g, '_')
    .replace(/\)/g, '_')
    .replace(/\\/g, '_')
    .replace(/\//g, '_')
    .replace(/-/g, '_')
    .replace(/_____/g, '_')
    .replace(/____/g, '_')
    .replace(/___/g, '_')
    .replace(/__/g, '_')
    .replace(/1/g, 'one')
    .replace(/2/g, 'two')
    .replace(/3/g, 'three')
    .toLowerCase();
  if (result.charAt(result.length - 1) === '_') {
    result = result.substring(0, result.length - 1);
  }
  return result;
}
