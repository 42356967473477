import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onAddToCart($event, _ctx.item))),
    "data-testid": `add-to-cart-button`
  }, [
    _createVNode("div", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.highlight), {
        hit: _ctx.item,
        attribute: "name"
      }, null, 8, ["hit"]))
    ]),
    _createVNode("div", _hoisted_1, [
      _createVNode("strong", null, _toDisplayString(_ctx.item.correctionDisplay), 1)
    ])
  ]))
}