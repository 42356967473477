
import { defineComponent, PropType } from 'vue';
import { Recommendation } from '@/models';

export default defineComponent({
  props: {
    recommendations: {
      type: Array as PropType<Array<Recommendation>>
    }
  },
  setup(props, { emit }) {
    function closeHandler() {
      emit('close');
    }

    return {
      closeHandler
    };
  }
});
