
import { defineComponent } from '@vue/runtime-core';
import CorrectionSearchResult from '@/models/CorrectionSearchResult';

export default defineComponent({
  props: {
    item: Object,
    highlight: Function
  },
  setup(
    _,
    { emit }
  ): {
    onAddToCart: (
      event: Event,
      correctionResult: CorrectionSearchResult
    ) => void;
  } {
    function onAddToCart(
      event: Event,
      correctionResult: CorrectionSearchResult
    ) {
      emit('selectedItemEvent', correctionResult);
    }

    return {
      onAddToCart
    };
  }
});
