
import { defineComponent } from '@vue/runtime-core';
import ReasonSearchResult from '@/models/ReasonSearchResult';

export default defineComponent({
  props: {
    item: Object,
    highlight: Function
  },
  setup(
    _,
    { emit }
  ): {
    onAddToCart: (event: Event, reasonSearchResult: ReasonSearchResult) => void;
  } {
    function onAddToCart(event: Event, reasonSearchResult: ReasonSearchResult) {
      emit('selectedItemEvent', reasonSearchResult);
    }

    return {
      onAddToCart
    };
  }
});
