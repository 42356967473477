<template>
  <div className="container">
    <div
      id="reasonAutoComplete"
      :data-testid="`${datatestId}`"
      @input="onKeyPress($event.target.value)"
    />
  </div>
</template>

<script lang="js">
import { Fragment, render, onMounted, defineComponent } from 'vue';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import ReasonSearchResultItem from './ReasonSearchResultItem.vue';
import { createElement } from './adapter';

export default defineComponent({
  name: 'App',
  props: {
    algoliaIndex: {
      type: String, required: true
    },
    optionalFiltersManeuverKeys: {
      type: Array, required: true
    },
    searchClient: {
      type: Object, required: true
    },
    datatestId: {
      type: String, required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    let autoComplete;

    function onSelectedItemEvent(reasonSearchResult) {
      emit('selectedItemEvent', reasonSearchResult);
      clearQuery()
    }

    function clearQuery() {
      if(autoComplete && autoComplete.setQuery) {
        autoComplete.setQuery('')
      }
    }

    function onKeyPress(value){
      emit('keyPress', value);
    }

    onMounted(() => {
      if(props.readonly) return
      props.searchClient.clearCache();
      autoComplete = autocomplete({
       onSubmit(e) {
          emit('enterPress', e.state.query);
        },
        container: '#reasonAutoComplete',
        placeholder: 'Reason',
        getSources({ query }) {
          return [
            {
              sourceId: 'products',
              getItems() {
                return getAlgoliaResults({
                  searchClient: props.searchClient,
                  queries: [
                    {
                      indexName: props.algoliaIndex,
                      optionalFilters: props.optionalFiltersManeuverKeys,
                      query
                    }
                  ]
                });
              },
              templates: {
                item({ item, components }) {
                  return (
                    <ReasonSearchResultItem
                      onSelectedItemEvent={onSelectedItemEvent}
                      item={item}
                      highlight={components.Highlight}
                    />
                  );
                }
              }
            }
          ];
        },
        renderer: {
          createElement,
          Fragment
        },
        render({ children }, root) {
          render(children, root);
        }
      });
    });
    return {
        onKeyPress,
      }
  }
});
</script>
