
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    timeout: {
      type: Number,
      default: 15000
    }
  },
  setup(props) {
    let show = ref(false);
    let timeout: number;
    let message = ref();
    let resolve = (confirm: boolean) => void confirm;

    function open(msg: string) {
      show.value = true;
      message.value = msg;
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        close();
      }, props.timeout);

      return new Promise((res: any) => {
        resolve = res;
      });
    }

    function confirm() {
      show.value = false;
      resolve(true);
    }

    function close() {
      show.value = false;
      resolve(false);
    }

    return {
      close,
      confirm,
      message,
      open,
      show
    };
  }
});
