import { renderSlot as _renderSlot, withModifiers as _withModifiers, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeHandler && _ctx.closeHandler(...args))),
    onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.closeHandler && _ctx.closeHandler(...args)), ["esc"])),
    class: "fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center bg-black bg-opacity-50",
    "data-testid": "modal-overlay"
  }, [
    _createVNode("div", {
      class: "relative z-50 rounded shadow bg-background",
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 32))
}